import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { userLogin } from './authActions'


export interface AuthSliceState {
	loading: boolean
	token: null | string
	error: null | string
}

export interface UserData {
	password: string
	token: string
	userName: string
}


// initialize userToken from local storage
const userToken = localStorage.getItem('userToken')
	? localStorage.getItem('userToken')
	: null

const initialState: AuthSliceState = {
	loading: false,
	token: null,
	error: null
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logout: (state) => {
			localStorage.removeItem('userToken') // delete token from storage
			state.loading = false
			state.token = null
			state.error = null
		},
		setCredentials: (state, { payload }) => {
			state.token = payload
		},
	},
	extraReducers: {
		//@ts-ignore
		[userLogin.pending]: (state) => {
			state.loading = true
			state.error = null
		},
		//@ts-ignore
		[userLogin.fulfilled]: (state, { payload }: PayloadAction<UserData>) => {
			state.loading = false
			state.token = payload.token
		},
		//@ts-ignore
		[userLogin.rejected]: (state, { payload }) => {
			state.loading = false
			state.error = payload
		}
	},
})

export const { logout, setCredentials } = authSlice.actions

export default authSlice.reducer