import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom'

import './App.css'
import StartGeneration from './components/StartGeneration'
import LoginScreen from './components/Auth/Login'
import ProtectedRoute from './routes/ProtectedRoute'
import VideoGuide from './components/VideoGuide'

function App() {
  return (
    <main className='container content'>
      <Routes>
        <Route path='/login' element={<LoginScreen />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/' element={<StartGeneration />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/videoGuide' element={<VideoGuide />} />
        </Route>
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </main>
  )
}

export default App