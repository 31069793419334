import { ReactNode } from 'react'

export enum stepStatus {
	finish = 'finish',
	process = 'process',
	wait = 'wait',
	error = 'error'
}


export interface FormInputs {
	token: string
	hostName: string
	userName: string
	password: string
	brightsitesHost: string
}