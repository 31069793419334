import { FC } from 'react'
import { Button, List } from 'antd'
import { useSelector } from 'react-redux'
import { CheckCircleOutlined } from '@ant-design/icons'

import styles from './style.module.css'
import { RootState } from '../../store'
import { useWidgetGenerateMutation } from '../../store/services/api'

const data = (hostName: string) => [
	{
		title: `The AdminPanel has been created: `,
		linkText: `${hostName}.mycustard.com`,
		link: `https://${hostName}.mycustard.com`
	},
	{
		title: 'The Database has been created'
	},
	{
		title: 'The backend has been created'
	}


]

interface Widgetgeneration {
	next: () => any
	prev: () => any
}

const WidgetGeneration: FC<Widgetgeneration> = ({ next, prev }) => {
	const dbConfigInputs = useSelector((state: RootState) => state.dbConfigInputs)
	const [widgetGenerate, { isLoading }] = useWidgetGenerateMutation()


	const onGenerate = async () => {
		await widgetGenerate({ hostName: dbConfigInputs.hostName })
		next()
	}

	const onPrev = () => prev()

	const onNext = () => next()

	return (<div className={styles.container}>
		<List
			className={styles.listContainer}
			size="small"
			bordered
			dataSource={data(dbConfigInputs.hostName)}
			renderItem={(item) => <List.Item><CheckCircleOutlined style={{ color: '#398613', marginRight: '10px' }} />
				{item.title} <a target='_blank' href={item.link} rel="noreferrer">{item.linkText}</a>
			</List.Item>}
		/>
		<div className={styles.btnConainer}>
			<Button
				onClick={onPrev}
				className={styles.btnPrev}
				type='default'
			>
				Prev
			</Button>
			<div className={styles.btnsRight}>
				<Button
					onClick={onGenerate}
					className={styles.btnNext}
					loading={isLoading}
					type='primary'
				>
					Download widget
				</Button>
				<Button
					onClick={onNext}
					type='default'
				>
					Next
				</Button>
			</div>		
		</div>

	</div>)
}
export default WidgetGeneration
