import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"

export interface AuthData {
	userName: string
	password: string
}

const backendURL = process.env.REACT_APP_HOST

export const userLogin = createAsyncThunk(
	'auth/login',
	async ({ userName, password }: AuthData, { rejectWithValue }) => {
		try {
			const config = {
				headers: {
					'Content-Type': 'application/json',
				},
			}
			const { data } = await axios.post(
				`${backendURL}/generateAccessToken`,
				{ userName, password },
				config
			)
			// store user's token in local storage
			localStorage.setItem('userToken', data.token)
			return data
		} catch (error: any) {
			// return custom error message from API if any
			if (error.response && error.response.data.message) {
				return rejectWithValue(error.response.data.message)
			} else {
				return rejectWithValue(error.message)
			}
		}
	}
)