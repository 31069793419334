import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react'

import { RootState } from '../index'

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_HOST,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).auth.token
		if (token) {
			headers.set('authorization', `Bearer ${token}`)
		}
		return headers
	},
})

const baseQueryWithRetry = retry(baseQuery, { maxRetries: 0 })

export const apiSlice = createApi({
	reducerPath: 'generateApi',
	baseQuery: baseQueryWithRetry,
	tagTypes: ['configDB'],
	endpoints: builder => ({
		createDB: builder.mutation({
			query: (iputs) => {
				return {
					url: 'generateDB',
					method: 'POST',
					body: iputs,
				}
			},
			//@ts-ignore
			providesTags: (result) => ['configDB']
		}),
	}),
})

export const {
	useCreateDBMutation
} = apiSlice

export const widgetGenerationSlice = createApi({
	reducerPath: 'widgetGenerateApi',
	baseQuery: baseQueryWithRetry,
	endpoints: builder => ({
		widgetGenerate: builder.mutation({
			query: (iputs) => {
				return {
					url: 'downloadWidget',
					method: 'POST',
					body: iputs,
					responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
					cache: "no-cache",
				}
			},
		}),
	}),
})

export const {
	useWidgetGenerateMutation
} = widgetGenerationSlice


export const checkDatabaseSlice = createApi({
	reducerPath: 'checkDatabaseApi',
	baseQuery: baseQueryWithRetry,
	endpoints: builder => ({
		checkExistDatabase: builder.mutation({
			query: (hostName) => {
				return {
					url: 'databaseIsExist',
					method: 'POST',
					body: { hostName }
				}
			},
		}),
	}),
})

export const {
	useCheckExistDatabaseMutation
} = checkDatabaseSlice