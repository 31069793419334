import React from 'react'
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { Controller, useForm } from 'react-hook-form'
import { Button, Input, notification } from 'antd'

import { RootState } from '../../store'
import { AuthData, userLogin } from '../../store/slices/authActions'
import Loading from './Loading'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import styles from './style.module.css'

const LoginScreen = () => {
	const { loading } = useSelector((state: RootState) => state.auth)
	const dispatch: ThunkDispatch<RootState, any, AnyAction> = useDispatch()
	const { register, handleSubmit, control, formState: { errors } } = useForm<AuthData>()
	const navigate = useNavigate()

	const submitForm = async (data: AuthData) => {
		const userData = await dispatch(userLogin(data))

		if (userData.payload.token) {
			return navigate('/')
		}
		notification.error({
			message: 'Authorization failed'
		})
	}

	return (<div className={styles.loginContainer}>
		<div className={styles.title}>widget generator</div>
		<div className={styles.formContainer}>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className={styles.input}>
					<Controller
						name='userName'
						control={control}
						rules={{
							required: true,
							maxLength: { value: 20, message: 'Max length 20 letters' }
						}}
						render={({ field }) => <Input {...field} />}
					/>
					{errors.userName && (
						<span className={styles.error}>{errors.userName.message}</span>
					)}
				</div>
				<div className={styles.input}>
					<Controller
						name='password'
						control={control}
						rules={{
							required: true,
						}}
						render={({ field }) => <Input.Password {...field} />}
					/>
					{errors.password && (
						<span className={styles.error}>{errors.password.message}</span>
					)}

				</div>
				<Button
					htmlType='submit'
					type='primary'
					loading={loading}
					className={styles.btnForm}
					disabled={loading}>
					Login
				</Button>
			</form>
		</div>
	</div>
	)
}

export default LoginScreen