import { Button } from 'antd'
import { FC } from 'react'

import styles from './style.module.css'

interface installationGuide {
	prev: () => void
}

const InstallationGuide: FC<installationGuide> = ({ prev }) => {

	const onClickBtnPrev = () => prev()

	return (<div className={styles.container}>
		<div className={styles.contentContainer}>
			Download to your Brightsites:
			<ol>
				<li>Go to the Brightsite admin panel, for exapmle: "<code>johnshopkinsmedicine.mybrightsites.com/admin</code>"</li>
				<li>Then click on the Design tab -&gt; Code files -&gt; assets folder</li>
				<li>Click on button "+ add folder" and then create "widget_points" folder</li>
				<li>Unzip and upload all files of the generated widget to the created folder "widget_points"</li>
			</ol>

			Adding widget files
			<ol>
				<li>Go to the file in Code files: layout -&gt; theme.liquid</li>
				<li>Insert these sctipts before close tag body:</li>
				<ul>
					<li><pre><code>{`{{ 'widget_points/bundle.widget.js' | asset_url | script_tag }}`}</code></pre></li>
					<li><pre><code>{`{{ 'widget_points/polyfills.widget.js' | asset_url | script_tag }}`}</code></pre></li>
				</ul>
				<li>Insert the hash number script (for example: widget_points/bundle.79b51.css) after the head tag:</li>
				<ul>
					<li><pre><code>{`{{ 'widget_points/bundle.<your_hash_number_see_in_widget_points_folder>.css' 
| asset_url | stylesheet_tag }}`}</code></pre></li>
				</ul>
				<li>Insert this html into the body tag:</li>
				<ul>
					<li><pre><code>{`<div data-widget-host="promo-points-widget" class="preview"></div>`}</code></pre></li>
				</ul>
				<li>Go to the file: snippets -&gt; checkout_summary.liquid, find  tag "input" with  type="submit" and add:</li>
				<ul>
					<li><pre><code>{`data-btn-id="order"`}</code></pre></li>
				</ul>
			</ol>



		</div>

		<div>
			<Button
				className={styles.btnNext}
				type='default'
				onClick={onClickBtnPrev}
			>
				Prev
			</Button>
		</div>

	</div>)
}
export default InstallationGuide
