import { FC } from 'react'
import { Button } from 'antd'

import styles from './style.module.css'
import { logout } from '../../store/slices/authSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Header: FC = () => {
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const onLogout = () => {
		dispatch(logout())
		navigate('/login')
	}

	const toGenerator = () => navigate('/')

	const toGuide = () => navigate('/videoGuide')

	return (<div className={styles.container}>
		<div className={styles.btnRigt}>
			<div className={styles.navigate}>
				<Button onClick={toGenerator} type='link'>Generator</Button>
				<Button type='link' onClick={toGuide}>Video guide</Button>
			</div>
			<Button onClick={onLogout} type='default'>LogOut</Button>
		</div>


	</div>)
}
export default Header
