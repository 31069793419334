import { FC } from 'react'

import videoGuide from '../../assets/widgetInstallation.mp4'
import styles from './style.module.css'
import Header from '../Header'

const VideoGuide: FC = () => {

	return <>
		<Header />
		<div className={styles.containerVideo}>
			<video width="1100" height="640" controls>
				<source src={videoGuide} type="video/mp4" />
				Your browser does not support the video tag.
			</video>
		</div>
	</>
}

export default VideoGuide
