import { Middleware, combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'

import dbConfigReducer from "./slices/dbSlice"
import authConfigReducer from "./slices/authSlice"
import { apiSlice, checkDatabaseSlice, widgetGenerationSlice } from './services/api'

const persistConfig = {
	key: 'root',
	storage,
}

const rootReducer = combineReducers({
	dbConfigInputs: dbConfigReducer,
	auth: authConfigReducer,
	[apiSlice.reducerPath]: apiSlice.reducer,
	[widgetGenerationSlice.reducerPath]: widgetGenerationSlice.reducer,
	[checkDatabaseSlice.reducerPath]: checkDatabaseSlice.reducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) => getDefaultMiddleware({
		serializableCheck: false,
	}).concat(apiSlice.middleware as Middleware)
		.concat(widgetGenerationSlice.middleware as Middleware)
		.concat(checkDatabaseSlice.middleware as Middleware)
	,
	devTools: process.env.NODE_ENV !== 'production'
})


export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch


export const persistor = persistStore(store)