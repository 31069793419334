import { FC } from 'react'
import { Button, Input, Popover, notification } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { useForm, Controller } from "react-hook-form"

import styles from './style.module.css'
import { RootState } from '../../store'
import { change } from '../../store/slices/dbSlice'
import { FormInputs } from '../../types'
import { useCheckExistDatabaseMutation, useCreateDBMutation } from '../../store/services/api'



const popoverContent = {
	hostName: 'Subdomain to be used as an example: mysubdomain.mycustard.com',
	brughtsiteHost: `Prefix from your brightsites subdomain.
	 For example, "johnshopkinsmedicine" from the full domain "johnshopkinsmedicine.mybrightsites.com"`,
	token: 'Generated token on brightsites to gain access to the Brightsites API',
	username: 'Username for the Admin panel',
	passw: 'Password for the Admin panel'
}

interface DBGeneration {
	next: () => any
}

const DbGeneration: FC<DBGeneration> = ({ next }) => {
	const { handleSubmit, control, formState: { errors }, watch } = useForm<FormInputs>()
	const watchFields = watch(["hostName"])
	const dbConfigInputs = useSelector((state: RootState) => state.dbConfigInputs)
	const dispatch = useDispatch()
	const [createDB, { isLoading }] = useCreateDBMutation()
	const [checkExistDatabase, { isLoading: isLoadingCheckingDatabase }] = useCheckExistDatabaseMutation()

	const onSubmit = async (data: FormInputs) => {
		const dataInputs = { ...data, hostName: data.hostName.toLowerCase() }
		dispatch(change(dataInputs))
		const generationResult = await createDB(dataInputs)
		// @ts-ignore
		if (generationResult.error) {
			return notification.error({
				// @ts-ignore
				message: generationResult.error.data?.message
			})
		}

		next()
	}

	const onNextStep = async () => {
		const isExistDbResp = await checkExistDatabase(watchFields.at(0) || dbConfigInputs.hostName)
		//@ts-ignore
		if (isExistDbResp.error) {
			return notification.error({
				//@ts-ignore
				message: isExistDbResp?.error.data.message
			})

		}
		return next()
	}

	return (<div className={styles.wrapperContainer}>
		<form onSubmit={handleSubmit(onSubmit)}>
			<div className={styles.centering}>
				<div className={styles.inputWrapper}>
					<div className={styles.inputLabel}>
						<Popover content={popoverContent.hostName}>
							Host name
						</Popover>
					</div>
					<Controller
						name='hostName'
						defaultValue={dbConfigInputs.hostName}
						control={control}
						rules={{
							required: true,
							maxLength: { value: 20, message: 'Max length 20 letters' },
							pattern: { value: /^[A-Za-z]+$/i, message: 'Use letters' }
						}}
						render={({ field }) => <Input {...field} />}
					/>
					{errors.hostName && (
						<span className={styles.error}>{errors.hostName.message}</span>
					)}
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.inputLabel}>
						<Popover content={popoverContent.brughtsiteHost}>
							Brightsites prefix URL name
						</Popover>
					</div>
					<Controller
						name='brightsitesHost'
						defaultValue={dbConfigInputs.hostName}
						control={control}
						rules={{
							required: true,
							maxLength: { value: 20, message: 'Max length 20 letters' },
							pattern: { value: /^[A-Za-z]+$/i, message: 'Use letters' }
						}}
						render={({ field }) => <Input {...field} />}
					/>
					{errors.brightsitesHost && (
						<span className={styles.error}>{errors.brightsitesHost.message}</span>
					)}
				</div>
				<div className={styles.inputWrapper}>
					<div className={styles.inputLabel}>
						<Popover content={popoverContent.token}>
							Token
						</Popover>
					</div>
					<Controller
						name='token'
						defaultValue={dbConfigInputs.token}
						control={control}
						rules={{ required: true }}
						render={({ field }) => <Input {...field} />}
					/>
					{errors.token && (
						<span className={styles.error}>{errors.token.message}</span>
					)}
				</div>

				<div className={styles.inputWrapper}>
					<div className={styles.inputLabel}>
						<Popover content={popoverContent.username}>
							Username
						</Popover>
					</div>
					<Controller
						name='userName'
						defaultValue={dbConfigInputs.userName}
						control={control}
						rules={{ required: true }}
						render={({ field }) => <Input {...field} />}
					/>
					{errors.userName && (
						<span className={styles.error}>{errors.userName.message}</span>
					)}
				</div>

				<div className={styles.inputWrapper}>
					<div className={styles.inputLabel}>
						<Popover content={popoverContent.passw}>
							Password
						</Popover>
					</div>
					<Controller
						name='password'
						defaultValue={dbConfigInputs.password}
						control={control}
						rules={{ required: true }}
						render={({ field }) => <Input.Password {...field} />}
					/>
					{errors.password && (
						<span className={styles.error}>{errors.password.message}</span>
					)}
				</div>
			</div>
			<div className={styles.btnConainer}>
				<Button className={styles.btnNext} loading={isLoading} type='primary' htmlType='submit'>
					Generate
				</Button>
				<Button onClick={onNextStep} className={styles.btnNext} loading={isLoadingCheckingDatabase} type='default'>
					Next
				</Button>
			</div>

		</form>
	</div>)
}
export default DbGeneration
