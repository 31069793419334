import React, { useState } from 'react'
import { Steps, theme } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import DbGeneration from '../DbGeneration'

import { stepStatus } from '../../types'
import WidgetGeneration from '../WidgetGeneration'
import InstallationGuide from '../InstallationGuide'
import Header from '../Header'

const steps = (next: any, prev: any) => [
	{
		title: 'Generation DB, configs',
		status: stepStatus.finish,
		content: <DbGeneration next={next!} />,
		icon: <LoadingOutlined />
	},
	{
		title: 'Download widget',
		status: stepStatus.process,
		content: <WidgetGeneration next={next!} prev={prev} />,
		icon: <LoadingOutlined />
	},
	{
		title: 'Installation guide',
		status: stepStatus.process,
		content: <InstallationGuide prev={prev} />,
	},
]

const StartGeneration: React.FC = () => {
	const { token } = theme.useToken()
	const [current, setCurrent] = useState(0)
	const [status, setStatus] = useState<'wait' | 'process' | 'finish' | 'error'>('wait')


	const next = () => {
		setCurrent(current + 1)
	}

	const prev = () => {
		setCurrent(current - 1)
	}

	const items = (next: () => any, prev: () => any) => steps(next, prev).map((item) => ({ key: item.title, title: item.title }))

	const contentStyle: React.CSSProperties = {
		minHeight: '260px',
		color: token.colorTextTertiary,
		backgroundColor: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: `1px dashed ${token.colorBorder}`,
		marginTop: 16,
		paddingLeft: '3rem'
	}

	return (<div>
		<Header />

		<div className='container mt-10'>
			<Steps
				status={status}
				current={current}
				items={items(next, prev)}
			/>
			<div style={contentStyle}>{steps(next, prev)[current].content}</div>

		</div>
	</div>
	)
}

export default StartGeneration
