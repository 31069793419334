import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FormInputs } from '../../types'


interface DbState {
	token: string
	hostName: string
	userName: string
	password: string
	brightsitesHost: string
}

const initialState: DbState = {
	token: '',
	hostName: '',
	userName: '',
	password: '',
	brightsitesHost: ''
}

const dbConfigReducerSlice = createSlice({
	name: 'configDB',
	initialState,
	reducers: {
		change(state, action: PayloadAction<FormInputs>) {
			return { ...state, ...action.payload }
		}
	},

})

export const { change } = dbConfigReducerSlice.actions
export default dbConfigReducerSlice.reducer